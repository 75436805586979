<template>
  <v-container style="max-width: none">
    <v-row>
      <v-col style="padding: 0 !important;">
        <v-dialog
          v-if="showModal"
          v-model="showModal"
          width="500"
          :style="styleObject"
        >
          <offer-form
            :oid="currentOffer"
            method="update"
            :callbacks="getCallbacks()"
          >
            <div
              slot="before-form"
              class="calculation-result"
            >
              <span class="label">Calculated Cost:</span> <span class="value">{{ calculatedCost }}</span>
            </div>
          </offer-form>
        </v-dialog>
        <transition
          name="simple-slide-03"
          appear
        >
          <v-data-table
            ref="offersTable"
            :headers="tableHeaders"
            :items="getTableData()"
            item-key="name"
            class="elevation-1 offers-table goldair-table-generic-style"
            :server-items-length="itemsCount"
            :loading="loading"
            sort-by="oid"
            :sort-desc="true"
            @update:options="updateOptions"
          >
            <template v-slot:item.options="{ item }">
              <div class="options-wrapper">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      class="view-btn"
                      @click="$router.push({ name: 'Offer/View', params: { oid: item.oid } })"
                      v-on="on"
                    >
                      mdi-eye-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dataheaders.view-offer') }}</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="primary"
                      class="edit-btn"
                      :disabled="item.IsExpired || item.HasBeenTurnedToOrder"
                      @click="$router.push({ name: 'Offer/Edit', params: { oid: item.oid } })"
                      v-on="on"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('dataheaders.edit-offer') }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <div class="status-wrapper">
                <span
                  v-if="item.IsExpired"
                  class="status expired"
                >
                  {{ $t('offer-status-expired') }}
                </span>
                <a
                  v-else-if="item.HasBeenTurnedToOrder"
                  class="status turned-to-order"
                  @click="$router.push({ name: 'Order/View', params:{ oid: item.OrderId } })"
                >
                  {{ $t('offer-order-no') }} #{{ item.OrderId }}
                </a>
                <v-btn
                  v-else-if="!customerCanPrintOffer"
                  color="primary"
                  class="create-order-btn"
                  @click="$router.push({ name: 'Order/Create', params:{ oid: item.oid } })"
                >
                  {{ $t('offer-create-order-btn') }}
                </v-btn>
              </div>
            </template>
            <template v-slot:loading>
              <loader />
            </template>
          </v-data-table>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import OfferForm from '@/components/Forms/OfferForm'
  import { authComputed } from '@/store/helpers'
  import Loader from '../../components/Forms/Elements/Loader'
  // import { apiClient } from '../../services/axiosService'
  // import Vue from 'vue'
  import i18n from '@/i18n'

  export default {
    name: 'MyOffers',
    components: {
      Loader,
      OfferForm,
    },
    data: () => ({
      search: '',
      showModal: false,
      currentOffer: null,
      calculatedCost: null,
      itemsCount: -1,
      loading: true,
      styleObject: {
        padding: '20px',
        'background-color': '#fff',
      },
      currentPage: [],
    }),
    computed: {
      ...authComputed,
      ...mapState('orders', ['myOffers']),
      ...mapGetters('apiGeneric', ['getCountriesSelectOptions', 'getPackagingTypesSelectOptions']),
      ...mapGetters('orders', ['getOfferByOid']),
      ...mapGetters('customer', ['customerCanPrintOffer']),
      tableHeaders () {
        return [
          {
            text: i18n.t('dataheaders.offerid'),
            value: 'oid',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.cost'),
            value: 'cost',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.country-from'),
            value: 'countryFrom',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.postcode-from'),
            value: 'postCodeFrom',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.country-to'),
            value: 'countryTo',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.postcode-to'),
            value: 'postCodeTo',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.num-of-items'),
            value: 'numOfItems',
            align: 'center',
            sortable: false,
          },
          {
            text: i18n.t('dataheaders.created-date'),
            value: 'createdDate',
            align: 'center',
          },
          {
            text: i18n.t('dataheaders.options'),
            value: 'options',
            align: 'center',
            sortable: false,
          },
          {
            text: i18n.t('dataheaders.status'),
            value: 'status',
            align: 'center',
            sortable: false,
          },
        ]
      },
    },
    async created () {
      const offersSummary = await this.$store.dispatch('orders/action_getOffersSummary')
      this.itemsCount = offersSummary.data.Total
      this.loading = false
    },
    methods: {
      ...mapGetters('customer', ['getCustomerEmail', 'getCustomerName']),
      ...mapActions('orders', ['action_getMyOffers']),
      initFormData: function () {
        const formData = {
          type: 'Offer',
          countriesOptions: this.getCountriesSelectOptions(),
          packagingTypesOptions: this.getPackagingTypesSelectOptions(),
        }

        if (this.$route.query.countryFrom) {
          const countryFromOid = this.getCountryIdByCode(this.$route.query.countryFrom.toUpperCase())
          if (countryFromOid) {
            formData.countryFrom = countryFromOid
          }
        }

        if (this.$route.query.countryTo) {
          const countryToOid = this.getCountryIdByCode(this.$route.query.countryTo.toUpperCase())
          if (countryToOid) {
            formData.countryTo = countryToOid
          }
        }

        if (this.$route.query.postCodeFrom) {
          formData.postcodeFrom = this.$route.query.postCodeFrom
        }

        if (this.$route.query.postCodeTo) {
          formData.postcodeTo = this.$route.query.postCodeTo
        }

        if (this.loggedIn) {
          formData.name = this.getCustomerName()
          formData.email = this.getCustomerEmail()
        }
        return formData
      },
      initFormMetaData: function () {
        return {
          disabled: ['type'],
          hidden: ['type', 'addressFrom', 'addressTo'],
          packagingTypesOptions: this.getPackagingTypesSelectOptions(),
        }
      },
      saveOffer: function () {
        const formData = JSON.parse(JSON.stringify(this.$refs.offerForm.order)) // create new instance in order to change type. if i use "formData=this.$refs.offerForm.order", then original data will be changed because of object refernece
        this.$refs.offerForm.callbacks.submit(formData, true)
      },
      getCallbacks: function () {
        return {
          success: (responseData, save) => {
            const data = responseData.data
            this.calculatedCost = data.Total
            if (save) {
              this.showModal = false
            }
          },
        }
      },
      getTableData: function () {
        const data = []
        const offerObjects = this.getOfferByOid(this.currentPage)
        for (let i = 0; i < offerObjects.length; i++) {
          data.push(
            {
              oid: offerObjects[i].Oid,
              cost: (Math.round((offerObjects[i].Total + Number.EPSILON) * 100) / 100).toFixed(2),
              countryFrom: offerObjects[i].CountryFrom.DisplayName,
              postCodeFrom: offerObjects[i].PostCodeFrom,
              countryTo: offerObjects[i].CountryTo.DisplayName,
              postCodeTo: offerObjects[i].PostCodeTo,
              numOfItems: offerObjects[i].Items.length,
              createdDate: moment(offerObjects[i].CreatedDate).format('HH:mm:ss DD/MM/YYYY'),
              HasBeenTurnedToOrder: offerObjects[i].HasBeenTurnedToOrder,
              IsExpired: offerObjects[i].IsExpired,
              OrderId: (offerObjects[i].Order) ? offerObjects[i].Order.Oid : 'null',
            },
          )
        }
        return data
      },
      getTableToApiLabel (tableLabel) {
        var apiLabel = ''
        switch (tableLabel) {
          case 'cost':
            apiLabel = 'Total'
            break
          case 'countryFrom':
            apiLabel = 'CountryFrom'
            break
          case 'postCodeFrom':
            apiLabel = 'PostCodeFrom'
            break
          case 'countryTo':
            apiLabel = 'CountryTo'
            break
          case 'postCodeTo':
            apiLabel = 'PostCodeTo'
            break
          case 'createdDate':
            apiLabel = 'CreatedDate'
            break
          default:
            apiLabel = tableLabel
        }
        return apiLabel
      },
      updateOptions: async function (options) {
        this.loading = true
        const payload = {
          params: {
            PageSize: options.itemsPerPage,
            Page: options.page,
          },
          skipCommit: true,
        }

        if (options.sortBy.length && options.sortDesc.length) {
          payload.params.OrderColumn = this.getTableToApiLabel(options.sortBy[0])
          payload.params.OrderAscending = !options.sortDesc[0]
        }
        this.action_getMyOffers(payload).then((response) => {
          this.$store.commit('orders/SET_MY_OFFERS', response)
          this.currentPage = []
          for (let i = 0; i < response.data.length; i++) {
            this.currentPage.push(response.data[i].Oid)
          }
        })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.account-details .v-card__text .label {
  font-weight: bold;
}

.calculation-result {
  font-size: 1.5em;
  padding: 10px;

  .label {
    font-weight: bold;
  }

  .value {

  }
}
</style>

<style lang="scss">
.v-data-table.goldair-table-generic-style {
  width: 90%;
  @media all and (max-width: 750px) {
    width: 100%;
  }
  background-color: $bgTransparent !important;
  box-shadow: none !important;

  .table-headers {
    margin-bottom: 10px;
  }

  tbody {
    tr {
      td {
        font-size: 15px !important;

        &:first-child {
          position: relative;

          &:before {
            content: '#';
            position: absolute;
            top: 50%;
            transform: translate(-9px, -50%);
          }
        }

        .status-wrapper {
          text-align: center !important;

          .status {
            font-size: 14px !important;

            &.expired {
              color: #f00;
            }

            &.turned-to-order {
              color: $goldairYellow;
            }
          }

          .create-order-btn {
            height: 31px;
            padding: 0 16px;

            span {
              font-size: 13px;
              text-transform: capitalize;
            }
          }
        }

        .options-wrapper {
          text-align: center;

          .v-btn, .v-icon {
            margin-right: 20px;
            font-size: 19px;
            @media all and (max-width: 750px) {
              margin-left: 20px !important;;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }

  .v-data-footer {
    background-color: $bgWhite08;
  }
}

tr.v-data-table__mobile-table-row {
  td.v-data-table__mobile-row {
    &:before {
      content: none !important;
    }

    .v-data-table__mobile-row__cell {
      &:before {
        content: '#';
        position: absolute;
        top: 50%;
        transform: translate(-9px, -50%);
      }
    }
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: rgba(255, 227, 153, 0.8);
}
</style>
