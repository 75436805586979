var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"none"}},[_c('v-row',[_c('v-col',{staticStyle:{"padding":"0 !important"}},[(_vm.showModal)?_c('v-dialog',{style:(_vm.styleObject),attrs:{"width":"500"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('offer-form',{attrs:{"oid":_vm.currentOffer,"method":"update","callbacks":_vm.getCallbacks()}},[_c('div',{staticClass:"calculation-result",attrs:{"slot":"before-form"},slot:"before-form"},[_c('span',{staticClass:"label"},[_vm._v("Calculated Cost:")]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.calculatedCost))])])])],1):_vm._e(),_c('transition',{attrs:{"name":"simple-slide-03","appear":""}},[_c('v-data-table',{ref:"offersTable",staticClass:"elevation-1 offers-table goldair-table-generic-style",attrs:{"headers":_vm.tableHeaders,"items":_vm.getTableData(),"item-key":"name","server-items-length":_vm.itemsCount,"loading":_vm.loading,"sort-by":"oid","sort-desc":true},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"options-wrapper"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"view-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Offer/View', params: { oid: item.oid } })}}},on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dataheaders.view-offer')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"edit-btn",attrs:{"color":"primary","disabled":item.IsExpired || item.HasBeenTurnedToOrder},on:{"click":function($event){return _vm.$router.push({ name: 'Offer/Edit', params: { oid: item.oid } })}}},on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dataheaders.edit-offer')))])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-wrapper"},[(item.IsExpired)?_c('span',{staticClass:"status expired"},[_vm._v(" "+_vm._s(_vm.$t('offer-status-expired'))+" ")]):(item.HasBeenTurnedToOrder)?_c('a',{staticClass:"status turned-to-order",on:{"click":function($event){return _vm.$router.push({ name: 'Order/View', params:{ oid: item.OrderId } })}}},[_vm._v(" "+_vm._s(_vm.$t('offer-order-no'))+" #"+_vm._s(item.OrderId)+" ")]):(!_vm.customerCanPrintOffer)?_c('v-btn',{staticClass:"create-order-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Order/Create', params:{ oid: item.oid } })}}},[_vm._v(" "+_vm._s(_vm.$t('offer-create-order-btn'))+" ")]):_vm._e()],1)]}},{key:"loading",fn:function(){return [_c('loader')]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }